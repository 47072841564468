import Lazyload from 'vanilla-lazyload';

export const lazyload = new Lazyload({
    elements_selector: 'img.lazy',
    threshold: 800,
    use_native: true,
});

export const videoLazyload = new Lazyload({
    elements_selector: 'video.lazy',
    threshold: 1000,
});
