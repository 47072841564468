import { getOffsetTop } from '../utils/dom';
import moveElementInsideViewport from '../utils/move-element-inside-viewport';

const CONTAINER_SELECTOR = '.js-dropdown';
const TOGGLER_SELECTOR = '.js-dropdown-toggler';
const TOGGLER_VALUE_SELECTOR = '.js-dropdown-toggler-value';
const OPTION_SELECTOR = '.js-dropdown-option';
const OPTION_ACTIVE_CLASS = 'dropdown-option--active';
const CONTENT_SELECTOR = '.js-dropdown-content';

const windowResizeListenersMap = new Map();

function setContentPosition(dropdownEl: Element) {
    const toggler = dropdownEl.querySelector<HTMLElement>('.js-dropdown-toggler');
    const content = dropdownEl.querySelector<HTMLElement>('.js-dropdown-content');

    if (!content || !toggler) return;

    content.classList.remove('bottom-left');
    content.classList.remove('bottom-right');
    content.classList.remove('top-left');
    content.classList.remove('top-right');

    setTimeout(() => {
        const marginY = 15;
        const rect = content.getBoundingClientRect();
        const togglerRect = toggler.getBoundingClientRect();
        content.style.width = '1px';
        // const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        content.style.width = '';

        // const positionX = rect.left + rect.width < windowWidth ? 'right' : 'left';
        const positionX = 'left';
        const positionY =
            togglerRect.top + togglerRect.height + rect.height + marginY + 10 <= windowHeight &&
            getOffsetTop(toggler) + togglerRect.height + rect.height + marginY + 10 <=
                document.documentElement.scrollHeight
                ? 'bottom'
                : 'top';

        content.classList.add(`${positionY}-${positionX}`);
    }, 1);
}

function close(dropdown: Element) {
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
    });
    dropdown.classList.remove('opened');
}

function closeAllDropdowns() {
    const dropdowns = Array.from(document.querySelectorAll(CONTAINER_SELECTOR));
    dropdowns.forEach((dropdown) => close(dropdown));
}

function open(dropdown: Element) {
    closeAllDropdowns();
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));
    const contentElement = dropdown.querySelector<HTMLElement>(CONTENT_SELECTOR);
    setContentPosition(dropdown);

    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'true');
    });

    if (contentElement) {
        // if (window.matchMedia('(max-width: 1024px').matches) {
        //     contentElement.style.left = '';
        // } else {
        moveElementInsideViewport(contentElement as HTMLElement, 30);
        // }
    }

    dropdown.classList.add('opened');
}

function pickValue(this: HTMLElement) {
    const dropdownElement = this.closest(CONTAINER_SELECTOR);

    if (dropdownElement) {
        const options = Array.from(dropdownElement.querySelectorAll(OPTION_SELECTOR));
        const dropdownTogglerValueEl = dropdownElement.querySelector(TOGGLER_VALUE_SELECTOR);

        options.forEach((option) => {
            option.classList.remove(OPTION_ACTIVE_CLASS);
        });

        this.classList.add(OPTION_ACTIVE_CLASS);

        if (dropdownTogglerValueEl && this.dataset.value) {
            dropdownTogglerValueEl.textContent = this.dataset.value;
        }

        close(dropdownElement);
    }
}

function toggle(this: Element) {
    const dropdown = this.closest(CONTAINER_SELECTOR);
    const isExpanded = this.getAttribute('aria-expanded') === 'true';

    if (!dropdown) return;

    if (isExpanded) {
        close(dropdown);
    } else {
        open(dropdown);
    }
}

function closeOnOutsideClick(event: Event) {
    const target = event.target as Element;
    if (!target.closest(CONTAINER_SELECTOR)) {
        closeAllDropdowns();
    }
}

function initDropdown(dropdownElement: Element) {
    const options = Array.from(dropdownElement.querySelectorAll(OPTION_SELECTOR));
    const togglers = Array.from(dropdownElement.querySelectorAll(TOGGLER_SELECTOR));

    options.forEach((option) => {
        option.addEventListener('click', pickValue);
    });

    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
        toggler.addEventListener('click', toggle);
    });
}

function destroyDropdown(dropdownElement: Element) {
    const options = Array.from(dropdownElement.querySelectorAll(OPTION_SELECTOR));
    const togglers = Array.from(dropdownElement.querySelectorAll<HTMLElement>(TOGGLER_SELECTOR));

    options.forEach((option) => {
        option.removeEventListener('click', pickValue);
    });

    togglers.forEach((toggler) => {
        toggler.removeEventListener('click', toggle);
    });
}

function init(container: Element | Document = document) {
    const dropdowns = Array.from(container.querySelectorAll<HTMLElement>(CONTAINER_SELECTOR));

    dropdowns.forEach((dropdown) => {
        if (dropdown.hasAttribute('data-init-on-media')) {
            const initOnResize = () => {
                const media = dropdown.dataset.initOnMedia;
                if (media && window.matchMedia(media).matches) {
                    initDropdown(dropdown);
                } else {
                    destroyDropdown(dropdown);
                }
            };
            initOnResize();
            window.addEventListener('resize', initOnResize);
            windowResizeListenersMap.set(dropdown, initOnResize);
        } else {
            initDropdown(dropdown);
        }
    });

    document.addEventListener('click', closeOnOutsideClick);
}

function destroy(container: Element | Document = document) {
    const dropdowns = Array.from(container.querySelectorAll<HTMLElement>(CONTAINER_SELECTOR));

    dropdowns.forEach((dropdown) => {
        const listener = windowResizeListenersMap.get(dropdown);

        if (listener) {
            window.removeEventListener('resize', listener);
            windowResizeListenersMap.delete(dropdown);
        }

        destroyDropdown(dropdown);
    });

    document.removeEventListener('click', closeOnOutsideClick);
}

const module = { init, destroy };

export default module;
