import barba from '@barba/core';
import dropdown from '../components/dropdown';
import btnUp from '../components/btn-up';
import wysiwyg from '../components/wysiwyg';
import { unlockBodyScroll } from '../utils/dom';
import inputMasks from '../components/masks';
import validateForms from '../components/validate-forms';
import dialogModals from '../components/dialog-modals';
import casesSections from '../components/cases-section';
import innerActions from '../components/inner-actions';
import animatingGradients from '../components/animating-gradients';
import { lazyload, videoLazyload } from '../lazy-load';

export function initBaseView() {
    let autoPlayedVideos: HTMLVideoElement[] = [];

    const autoplayedVideosPlayStateObserver = new IntersectionObserver(
        (entries, obs) => {
            entries.forEach((entry) => {
                const target = entry.target as HTMLElement;

                if (target instanceof HTMLVideoElement) {
                    if (entry.isIntersecting) {
                        if (target.loop || (!target.loop && !target.ended)) {
                            target.play().catch((err) => {
                                if (err.name === 'NotAllowedError') {
                                    obs.unobserve(target);

                                    if (!target.hasAttribute('data-video-preserve')) {
                                        target.remove();
                                    }
                                }
                            });
                        }
                    } else {
                        target.pause();
                    }
                }
            });
        },
        { rootMargin: '100px' },
    );

    barba.hooks.beforeEnter((data) => {
        if (data) {
            dropdown.init();
            wysiwyg.init();
            dialogModals.init(data.next.container);

            inputMasks.init(data.next.container);
            validateForms.init(data.next.container);
            btnUp.init(data.next.container);
            casesSections.init(data.next.container);
            innerActions.init(data.next.container);
            animatingGradients.init(data.next.container);

            autoPlayedVideos = Array.from(data.next.container.querySelectorAll<HTMLVideoElement>('video[autoplay]'));

            autoPlayedVideos.forEach((video) => {
                autoplayedVideosPlayStateObserver.observe(video);
            });
        }
    });

    barba.hooks.enter((data) => {
        if (data && data.current && data.next && data.current.namespace && data.next.namespace) {
            document.documentElement.classList.replace(data.current.namespace, data.next.namespace);
        }

        lazyload.update();
        videoLazyload.update();
    });

    barba.hooks.beforeLeave((data) => {
        autoPlayedVideos.forEach((el) => {
            autoplayedVideosPlayStateObserver.unobserve(el);
        });
        autoPlayedVideos = [];

        if (data) {
            dialogModals.destroy(data.current.container);
            casesSections.destroy(data.current.container);
            innerActions.destroy(data.current.container);
        }
    });

    barba.hooks.afterLeave((data) => {
        inputMasks.destroy(data?.current.container);
        validateForms.destroy(data?.current.container);
        btnUp.destroy(data?.current.container);
        animatingGradients.destroy(data?.current.container);
        if (data) {
            document.body.classList.remove(data.current.namespace);
        }
        unlockBodyScroll();
    });
}
