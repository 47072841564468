import type { InputMask } from 'imask';
import { fetchIMask } from '../dynamic-modules';

const map = new WeakMap<Element, InputMask<any>>();

function getInstanceByElement(element: Element) {
    return map.get(element);
}

export async function initPhoneMask(input: HTMLInputElement) {
    const IMask = await fetchIMask();

    const imask = IMask(input, {
        // mask: '{000}-{000}-{0000}',
        mask: `+${'0'.repeat(14)}`,
    });
    map.set(input, imask);
}

export function destroyPhoneMask(input: HTMLInputElement) {
    const imask = getInstanceByElement(input);
    if (imask) {
        imask.destroy();
        map.delete(input);
    }
}

function initPhoneMasks(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    if (elements.length > 0) {
        elements.forEach((el) => {
            initPhoneMask(el);
        });
    }
}

function init(container: Element | Document = document) {
    initPhoneMasks(container);
}

function destroy(container: Element | Document = document) {
    const phoneElements = Array.from(container.querySelectorAll<HTMLInputElement>('input[data-mask="phone"]'));

    phoneElements.forEach((el) => {
        destroyPhoneMask(el);
    });
}

const _module = { init, destroy, getInstanceByElement };

export default _module;
