/* eslint-disable max-lines */
import gsap from 'gsap';
import debounce from 'lodash.debounce';
import { viewport } from '../utils/viewport';

export const ZENO_FRAME_COUNT = 505;

export const sequenceState: { frameCount: number; images: HTMLImageElement[]; loaded: number } = {
    frameCount: ZENO_FRAME_COUNT,
    images: [],
    loaded: 0,
};

const format = document.documentElement.classList.contains('avif') ? 'avif' : 'webp';

const currentFrame = (name: string, index: number) =>
    `/static/sequences/${name}/${matchMedia('(orientation: portrait)').matches ? 'mobile' : 'desktop'}/${(index + 1)
        .toString()
        .padStart(4, '0')}.${format}`;

let heroBtnUp: HTMLElement | null;
let heroContent: HTMLElement | null;
let heroButtonsList: HTMLElement | null;
let sequenceCard: HTMLElement[] = [];
let canvas: HTMLCanvasElement | null;
let context: CanvasRenderingContext2D | null;
let fullSt: gsap.core.Tween | null;
let lastSt: gsap.core.Tween | null;
let st: gsap.core.Tween | null;
const seq = { frame: 0 };
let prevFrame = -1;
let trigger: HTMLElement | null;

function render(force = false) {
    if (canvas && context && sequenceState.images[seq.frame] && (force ? true : seq.frame !== prevFrame)) {
        const isMobile = matchMedia('(max-width: 1024px) and (orientation: portrait)').matches;

        const imgWidth = isMobile ? 540 : 1980;
        const imgHeight = isMobile ? 960 : 1240;
        const imgAspectRatio = imgWidth / imgHeight;
        const canvasAspectRatio = canvas.width / canvas.height;
        let x = 0;
        let y = 0;
        let width = canvas.width;
        let height = canvas.height;

        if (imgAspectRatio < canvasAspectRatio) {
            height = width / imgAspectRatio;
            y = (canvas.height - height) / 2;
        } else {
            width = height * imgAspectRatio;
            x = (canvas.width - width) / 2;
        }

        context.drawImage(sequenceState.images[seq.frame], x, y, width, height);

        if (sequenceState.images[seq.frame].complete) {
            prevFrame = seq.frame;
        }
    }
}

function setCanvasDimensions() {
    const dpr = Math.min(window.devicePixelRatio, 2);
    if (canvas) {
        canvas.width = canvas.offsetWidth * dpr;
        canvas.height = canvas.offsetHeight * dpr;
        render(true);
    }
}

function onResize() {
    setCanvasDimensions();
}

let animating = false;
let snapPointsMap: number[][] = [];

function goToSection(i: number, duration = 2) {
    animating = true;
    gsap.to(window, {
        scrollTo: {
            y: snapPointsMap[i][2] * (trigger!.scrollHeight - viewport.height),
            autoKill: false,
        },
        ease: 'none',
        overwrite: true,
        duration: Math.max(duration, 1.5),
        onComplete: () => {
            setTimeout(() => {
                animating = false;
            }, 500);
        },
    });
}

function onUpBtnClick() {
    goToSection(0, 2);
}

const debouncedOnResize = debounce(onResize, 100);

function fetch() {
    sequenceState.loaded = 0;

    /**
     * Загрузка изображений для секвенции
     */
    for (let i = 0; i < sequenceState.frameCount; i++) {
        const img = new Image();
        const onLoad = () => {
            sequenceState.loaded++;

            document.dispatchEvent(
                new CustomEvent('sequence-img-loaded', {
                    detail: { loaded: sequenceState.loaded, total: Math.round(sequenceState.frameCount / 2) },
                }),
            );

            if (sequenceState.loaded === Math.round(sequenceState.frameCount / 2)) {
                document.dispatchEvent(new Event('sequence-loaded'));
            }
        };
        img.addEventListener('load', onLoad, { once: true });
        img.addEventListener('error', onLoad, { once: true });

        img.src = currentFrame('zeno', i);
        sequenceState.images[i] = img;
    }
}

function init(container: HTMLElement | Document = document) {
    heroBtnUp = container.querySelector<HTMLElement>('.js-btn-up');
    heroContent = container.querySelector<HTMLElement>('.js-hero-content');
    heroButtonsList = container.querySelector<HTMLElement>('.js-hero-button-list');
    canvas = container.querySelector<HTMLCanvasElement>('canvas.js-product-canvas');
    sequenceCard = Array.from(container.querySelectorAll<HTMLElement>('.js-hero-content-block'));
    trigger = container.querySelector<HTMLElement>('.js-product-sequence');
    snapPointsMap = [
        [0, 0, 0],
        ...sequenceCard.map((el) => [
            parseFloat(el.dataset.scrollProgressMin!),
            parseFloat(el.dataset.scrollProgressMax!),
            parseFloat(el.dataset.scrollProgressValue!),
        ]),
    ];

    if (canvas) {
        context = canvas.getContext('2d');
        setCanvasDimensions();

        fullSt = gsap.to(
            {},
            {
                ease: 'none',
                scrollTrigger: {
                    trigger: trigger,
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: true,
                    onUpdate: ({ progress }) => {
                        if (progress >= 0 && progress <= 0.04) {
                            heroContent?.classList.add('is-active');
                            heroBtnUp?.classList.add('is-hidden');
                            heroButtonsList?.classList.add('is-hidden');
                        } else {
                            heroContent?.classList.remove('is-active');
                            heroBtnUp?.classList.remove('is-hidden');
                            heroButtonsList?.classList.remove('is-hidden');

                            for (let i = 0; i < sequenceCard.length; i++) {
                                const [scrollProgressMin, scrollProgressMax] = snapPointsMap[i + 1];

                                if (progress >= scrollProgressMin && progress <= scrollProgressMax + 0.001) {
                                    sequenceCard[i].classList.add('is-active');
                                } else {
                                    sequenceCard[i].classList.remove('is-active');
                                }
                            }
                        }
                    },
                },
            },
        );

        heroBtnUp?.addEventListener('click', onUpBtnClick);

        st = gsap.to(seq, {
            frame: sequenceState.frameCount - 1,
            snap: 'frame',
            ease: 'none',
            scrollTrigger: {
                trigger: trigger,
                start: 'top top',
                end: () => `bottom-=${viewport.height} bottom`,
                scrub: true,
                invalidateOnRefresh: false,
                onUpdate: ({ progress, direction, getVelocity }) => {
                    if (!animating) {
                        for (let i = 0; i < sequenceCard.length; i++) {
                            const [, , scrollProgressValue] = snapPointsMap[i];

                            if (direction === 1) {
                                if (
                                    progress > (i === 0 ? 0 : snapPointsMap[i - 1][2] + 0.0001) &&
                                    progress <= scrollProgressValue
                                ) {
                                    // requestAnimationFrame(() => {
                                    goToSection(
                                        i,
                                        (scrollProgressValue - (i === 0 ? 0 : snapPointsMap[i - 1][2] + 0.0001)) * 13,
                                    );
                                    // });
                                    break;
                                }
                            }

                            if (direction === -1) {
                                if (progress > snapPointsMap[i][2] && progress <= snapPointsMap[i + 1][2]) {
                                    // requestAnimationFrame(() => {
                                    goToSection(
                                        Math.max(0, i - 1),
                                        (snapPointsMap[Math.max(i)][2] - snapPointsMap[Math.max(0, i - 1)][2]) * 13,
                                    );
                                    // });
                                    break;
                                }
                            }
                        }
                    }

                    if (getVelocity() !== 0) {
                        render();
                    }
                },
            },
            // onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
        });

        const progress = {
            val: 0,
        };

        const canvasWrapper = container.querySelector<HTMLElement>('.js-product-canvas-wrapper');
        const canvasEl = container.querySelector<HTMLElement>('.js-product-canvas');

        lastSt = gsap.to(progress, {
            val: 1,
            ease: 'none',
            scrollTrigger: {
                trigger: trigger,
                start: () => `bottom-=${viewport.height} bottom`,
                end: `bottom bottom`,
                scrub: true,
                onUpdate: ({ progress }) => {
                    if (canvasWrapper) {
                        canvasWrapper.style.transform = `translate3d(${-progress * 100}%, 0, 0)`;
                    }

                    if (canvasEl) {
                        canvasEl.style.transform = `translate3d(${progress * 100}%, 0, 0)`;
                    }
                },
            },
        });

        if (sequenceState.images[0]) {
            if (sequenceState.images[0].complete) {
                render();
            } else {
                sequenceState.images[0].onload = () => render();
            }
        }
    }

    window.addEventListener('resize', debouncedOnResize);
}

function destroy(container: HTMLElement | Document = document) {
    window.removeEventListener('resize', debouncedOnResize);
    heroBtnUp?.removeEventListener('click', onUpBtnClick);

    if (fullSt) {
        fullSt.scrollTrigger?.kill();
        fullSt.kill();
        fullSt = null;
    }

    if (st) {
        st.scrollTrigger?.kill();
        st.kill();
        st = null;
    }

    if (lastSt) {
        lastSt.scrollTrigger?.kill();
        lastSt.kill();
        lastSt = null;
    }
}

const _module = { fetch, init, destroy };

export default _module;
