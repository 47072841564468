import barba from '@barba/core';
import gsap from 'gsap';
import delegate from 'delegate';
import { lerp } from '../utils/lerp';
import { viewport } from '../utils/viewport';

export const circleSelector = '[data-cursor="circle"]';

function Cursor() {
    const lerpValue = 0.09;
    let rAF: number;
    let clientX = 0;
    let clientY = 0;
    const settings = {
        scale: 0,
    };

    const cursorContainer = document.createElement('div');
    cursorContainer.className = 'cursor js-cursor';
    cursorContainer.style.visibility = 'hidden';

    const outerCursor = document.createElement('div');
    outerCursor.className = 'circle-cursor--outer';
    cursorContainer.appendChild(outerCursor);

    const innerCursor = document.createElement('div');
    innerCursor.className = 'circle-cursor circle-cursor--inner';
    const textEl = document.createElement('div');
    textEl.className = 'circle-cursor--inner-text';
    innerCursor.appendChild(textEl);

    let outerCursorX = viewport.width / 2;
    let outerCursorY = viewport.height / 2;

    cursorContainer.appendChild(innerCursor);
    document.body.appendChild(cursorContainer);

    function getMouseCoords(event: MouseEvent) {
        clientX = event.clientX;
        clientY = event.clientY;
    }

    function onLeave() {
        gsap.to(settings, { scale: 0, duration: 0.3, ease: 'power3.out' });
    }

    function initHovers() {
        delegate(
            document,
            circleSelector,
            'mouseover',
            (event: any) => {
                const target = event.delegateTarget;
                gsap.to(settings, { scale: 1, duration: 0.3, overwrite: true, ease: 'power3.out' });

                if (target instanceof HTMLElement) {
                    const text = target.dataset.cursorText;

                    if (text) {
                        setCursorText(text);
                    }
                }
            },
            true,
        );
        delegate(document, circleSelector, 'mouseleave', onLeave, true);
    }

    function render() {
        outerCursorX = lerp(outerCursorX, clientX, lerpValue);
        outerCursorY = lerp(outerCursorY, clientY, lerpValue);
        outerCursor.style.transform = `translate3d(${outerCursorX}px, ${outerCursorY}px, 0)`;
        innerCursor.style.transform = `translate3d(${outerCursorX}px, ${outerCursorY}px, 0) scale(${settings.scale})`;
    }

    function animate() {
        render();
        rAF = requestAnimationFrame(animate);
    }

    function unveilCursor() {
        document.removeEventListener('mousemove', unveilCursor);
        animate();
        gsap.set(cursorContainer, { clearProps: 'visibility' });
    }

    function init() {
        document.addEventListener('mousemove', unveilCursor);
        document.addEventListener('mousemove', getMouseCoords);
        initHovers();
    }

    function destroy() {
        cancelAnimationFrame(rAF);
        document.removeEventListener('mousemove', unveilCursor);
        document.removeEventListener('mousemove', getMouseCoords);
    }

    function setCursorText(text: string) {
        const { textContent } = textEl;

        if (textContent === text) return;

        const tl = gsap.timeline();

        if (textContent) {
            tl.to(textEl, {
                duration: 0.2,
                opacity: 0,
                y: 10,
            });
        }

        tl.to(textEl, {
            duration: 0.2,
            opacity: 1,
            y: 0,
            onStart: () => {
                textEl.textContent = text;
            },
        });
    }

    barba.hooks.afterLeave(() => {
        setTimeout(onLeave, 1);
    });

    return Object.freeze({
        init,
        destroy,
        setCursorText,
    });
}

export const cursor = Cursor();
cursor.init();
