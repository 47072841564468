import { LitElement, css, html, unsafeCSS } from 'lit';
import MarqueeConstructor from '../../components/marquee';
import _styles from './MarqueeText.ce.scss';

export interface MarqueeText {
    speed: number;
    _marquee: MarqueeConstructor | undefined;
}

export class MarqueeText extends LitElement {
    constructor() {
        super();
        this.speed = 0.4;
    }

    static get properties() {
        return {
            speed: {
                type: Number,
            },
        };
    }

    static get styles() {
        return css`
            ${unsafeCSS(_styles)}
        `;
    }

    protected createRenderRoot(): Element | ShadowRoot {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => {
            // const container = this.renderRoot.querySelector<HTMLElement>('.container');

            // if (container) {
            this._marquee = new MarqueeConstructor(this, { speed: this.speed });
            // }
        }, 0);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._marquee?.destroy();
    }

    // render() {
    //     return html` <div class="container">${this.children}</div> `;
    // }
}

customElements.define('app-marquee', MarqueeText);

declare global {
    interface HTMLElementTagNameMap {
        'x-marquee': MarqueeText;
    }
}
