import './LitPopup.scss';
import { LitElement } from 'lit';
import LitPopup from 'lit-popup';
import { getCSSCustomProp } from '../../utils/css';
import { NO_SCROLL_CLASS, lockBodyScroll, unlockBodyScroll } from '../../utils/dom';
import { timeout } from '../../utils/timeout';

export interface LitPopupElement {
    instance: LitPopup;
    _wasBodyLocked: boolean;
    _leaveTimeout: NodeJS.Timeout;
}

export class LitPopupElement extends LitElement {
    constructor() {
        super();
        this._wasBodyLocked = false;
    }

    static get properties() {
        return {
            name: {
                type: Boolean,
            },
            opened: {
                type: String,
            },
        };
    }

    createRenderRoot() {
        /**
         * Render template without shadow DOM. Note that shadow DOM features like
         * encapsulated CSS and slots are unavailable.
         */
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        const name = this.getAttribute('data-lit-popup');
        const leaveDurationInSeconds = getCSSCustomProp(this, '--leave-duration', 'number') as number;
        const isNoScrollLock = this.hasAttribute('data-no-scroll-lock');
        const isSwapTrigger = this.hasAttribute('data-swap-trigger');

        if (!name) {
            throw new Error('[lit-popup] Name should be provided.');
        }

        this.instance = new LitPopup(name, {
            onOpen: () => {
                clearTimeout(this._leaveTimeout);
                if (!isNoScrollLock || window.matchMedia('(max-width: 767px)').matches) {
                    this._lockBodyScroll();
                }

                if (isSwapTrigger) {
                    const trigger = document.querySelector(`[data-lit-popup-open="${name}"]`);
                    setTimeout(() => {
                        trigger?.removeAttribute('data-lit-popup-open');
                        trigger?.setAttribute('data-lit-popup-close', name);
                    }, 1);
                }
            },
            onOpenComplete: () => {
                const focusableOnOpenElement = this.renderRoot.querySelector<HTMLElement>('[data-focus-on-popup-open]');
                setTimeout(() => focusableOnOpenElement?.focus({ preventScroll: true }), 50);
            },
            onClose: () => {
                this._leaveTimeout = setTimeout(() => {
                    this._unlockBodyScroll();
                }, leaveDurationInSeconds * 1000);

                if (isSwapTrigger) {
                    const trigger = document.querySelector(`[data-lit-popup-close="${name}"]`);
                    setTimeout(() => {
                        trigger?.removeAttribute('data-lit-popup-close');
                        trigger?.setAttribute('data-lit-popup-open', name);
                    }, 1);
                }
            },
            closeAnimation: () => timeout(250),
        });
    }

    attributeChangedCallback(name: string, oldVal: string | null, newVal: string | null) {
        super.attributeChangedCallback(name, oldVal, newVal);

        if (name === 'opened') {
            if (typeof newVal === 'string') {
                this.instance.open();
            } else {
                this.instance.close();
            }
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        clearTimeout(this._leaveTimeout);

        if (this.instance) {
            this.instance.destroy();
        }
    }

    open() {
        this.instance?.open();
    }

    close() {
        this.instance?.close();
    }

    protected _lockBodyScroll() {
        this._wasBodyLocked = document.body.classList.contains(NO_SCROLL_CLASS);

        if (!this._wasBodyLocked) {
            lockBodyScroll();
        }
    }

    protected _unlockBodyScroll() {
        if (!this._wasBodyLocked) {
            unlockBodyScroll();
        }
    }
}

customElements.define('app-lit-popup', LitPopupElement);

declare global {
    interface HTMLElementTagNameMap {
        'app-lit-popup': LitPopupElement;
    }
}
