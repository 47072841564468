import './webpack-imports';
import './sentry';
import './custom-elements';
import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { CustomEase } from 'gsap/CustomEase';
import { applyPolyfills } from './polyfills';
import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';
import BaseTransition from './transitions/base';
import './lazy-load';
import './components/cursor';
import { LitPopupElement } from './custom-elements/LitPopupElement/LitPopupElement';
import preloader from './components/preloader';
import navbar from './components/nav-bar';
import { remap } from './utils/math';
import vhMobileFix from '../modules/vh-mobile-fix';
import { isSafariBrowser } from './utils/is-safari';

gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin, CustomEase, ScrollToPlugin);

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');

    vhMobileFix();
    history.scrollRestoration = 'manual';

    if (isSafariBrowser()) {
        document.documentElement.classList.add('safari');
    }

    applyPolyfills().then(() => {
        const menu = document.querySelector<LitPopupElement>('app-lit-popup.js-menu');
        const header = document.querySelector<HTMLElement>('.js-header');

        initBaseView();

        barba.hooks.afterLeave(() => {
            menu?.close();
        });

        preloader.init();
        navbar.init();

        menu?.addEventListener('open', () => {
            document.documentElement.classList.add('menu-opened');
        });

        menu?.addEventListener('close', () => {
            document.documentElement.classList.remove('menu-opened');
        });

        document.addEventListener('scroll', () => {
            const { scrollY } = window;
            const progress = remap(scrollY, [0, 400], [0, 1], true);

            if (header) {
                header.style.setProperty('--header-bg-opacity', `${progress}`);
            }
        });

        barba.init({
            transitions: [BaseTransition],
            views: [
                lazyView('index-page', () =>
                    import(/* webpackChunkName: "index-page" */ './views/index').then((m) => m.default),
                ),
                lazyView('new-main-page', () =>
                    import(/* webpackChunkName: "new-main-page", webpackPrefetch: true */ './views/new-main').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('zeno-page', () =>
                    import(/* webpackChunkName: "zeno-page" */ './views/zeno').then((m) => m.default),
                ),
                lazyView('zeno-2-page', () =>
                    import(/* webpackChunkName: "zeno-2-page" */ './views/zeno-2').then((m) => m.default),
                ),
                lazyView('account-page', () =>
                    import(/* webpackChunkName: "account-page" */ './views/account').then((m) => m.default),
                ),
                lazyView('trial-page', () =>
                    import(/* webpackChunkName: "trial-page" */ './views/trial').then((m) => m.default),
                ),
                lazyView('contacts-page', () =>
                    import(/* webpackChunkName: "contacts-page", webpackPrefetch: true */ './views/contacts').then(
                        (m) => m.default,
                    ),
                ),
                lazyView('studios-page', () =>
                    import(/* webpackChunkName: "studios-page" */ './views/studios').then((m) => m.default),
                ),
                lazyView('case-page', () =>
                    import(/* webpackChunkName: "case-page" */ './views/case').then((m) => m.default),
                ),
                lazyView('booking-page', () =>
                    import(/* webpackChunkName: "booking-page" */ './views/booking').then((m) => m.default),
                ),
                lazyView('certified-page', () =>
                    import(/* webpackChunkName: "certified-page" */ './views/certified').then((m) => m.default),
                ),
            ],
            prefetchIgnore: true,
            preventRunning: true,
            prevent: ({ el }) => el.tagName === 'A' && (!!el.closest('#bx-panel') || !!el.closest('html')),
            timeout: 5000,
            debug: NODE_ENV === 'development',
        });
    });
});
