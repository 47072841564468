import type { ITransitionPage } from '@barba/core';
import gsap from 'gsap';
import { timeout } from '../utils/timeout';
import { sequenceState } from '../components/product-sequence';

const curtain = document.querySelector<HTMLElement>('.js-curtain');

export default {
    beforeLeave(): Promise<void> {
        return new Promise((resolve) => {
            gsap.to(curtain, {
                duration: 0.5,
                ease: 'power1.inOut',
                transformOrigin: '50% 100%',
                scaleY: 1,
                onComplete: resolve,
            });
        });
    },

    async enter({ next }): Promise<void> {
        window.scrollTo({ top: 0, behavior: 'auto' });

        const waitForSequenceToLoad = (): Promise<void> =>
            new Promise((resolve) => {
                if (sequenceState.loaded >= Math.round(sequenceState.frameCount / 2)) {
                    resolve();
                } else {
                    document.addEventListener(
                        'sequence-loaded',
                        () => {
                            resolve();
                        },
                        { once: true },
                    );
                }
            });

        await Promise.all([next.namespace === 'zeno-page' ? waitForSequenceToLoad() : Promise.resolve(), timeout(200)]);

        gsap.to(curtain, { duration: 0.5, ease: 'power3.inOut', transformOrigin: '50% 0%', scaleY: 0 });
    },
} satisfies ITransitionPage;
