import { gsap } from 'gsap';

const tweensMap = new WeakMap<HTMLElement, gsap.core.Tween>();

function init(container: HTMLElement | Document = document) {
    if (!CSS.supports('background', 'paint(something)')) {
        Array.from(container.querySelectorAll<HTMLElement>('.js-animating-gradient')).forEach((el) => {
            const tween = gsap.fromTo(
                el,
                { '--gradient-deg': '0deg' },
                { '--gradient-deg': '360deg', repeat: -1, duration: 2, ease: 'none' },
            );
            tweensMap.set(el, tween);
        });
    }
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-animating-gradient')).forEach((el) => {
        const tween = tweensMap.get(el);

        if (tween) {
            tween.kill();
        }

        tweensMap.delete(el);
    });
}

const _module = { init, destroy };

export default _module;
