let innerActionsBlock: HTMLElement | null;

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (innerActionsBlock) {
                if (entry.isIntersecting) {
                    innerActionsBlock.classList.add('is-hidden');
                } else {
                    innerActionsBlock.classList.remove('is-hidden');
                }
            }
        });
    },
    { threshold: [0, 1] },
);

function init(container: HTMLElement | Document = document) {
    innerActionsBlock = container.querySelector('.js-inner-actions');

    if (innerActionsBlock) {
        Array.from(container.querySelectorAll<HTMLElement>('.js-inner-actions-trigger')).forEach((el) => {
            observer.observe(el);
        });
    }
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>('.js-inner-actions-trigger')).forEach((el) => {
        observer.unobserve(el);
    });
}

export default { init, destroy };
