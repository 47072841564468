export function clamp(value: number, min: number, max: number) {
    return min < max ? (value < min ? min : value > max ? max : value) : value < max ? max : value > min ? min : value;
}

export function remap(x: number, [low1, high1]: number[], [low2, high2]: number[], clamped = false) {
    return clamp(
        low2 + ((x - low1) * (high2 - low2)) / (high1 - low1),
        clamped ? low2 : -Infinity,
        clamped ? high2 : Infinity,
    );
}
