import throttle from 'lodash.throttle';
import { clamp } from '../utils/math';
import { viewport } from '../utils/viewport';

function init() {
    const header = document.querySelector('.js-header');

    /**
     * Показать/скрыть навбар по скроллу
     */
    let prevScrollY = window.scrollY;
    let deltaScrollY = 0;

    document.addEventListener(
        'scroll',
        throttle(() => {
            const scrollY = clamp(window.scrollY, 0, document.documentElement.scrollHeight - viewport.height);
            deltaScrollY = Math.abs(scrollY - prevScrollY);

            if (deltaScrollY > 20) {
                if (scrollY > prevScrollY) {
                    header?.classList.add('is-hidden');
                } else {
                    header?.classList.remove('is-hidden');
                }
            }

            prevScrollY = scrollY;
        }, 100),
    );
}

export default { init };
