function upPage() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

function init(container: Element | Document = document) {
    const btns = Array.from(container.querySelectorAll('.js-btn-up'));

    btns.forEach((btn) => {
        btn.addEventListener('click', upPage);
    });
}

function destroy(container: Element | Document = document) {
    const btns = Array.from(container.querySelectorAll<HTMLElement>('.js-btn-up'));

    btns.forEach((btn) => {
        btn.removeEventListener('click', upPage);
    });
}

const module = { init, destroy };

export default module;
