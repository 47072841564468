const SECTION_CLASS = 'js-cases-section';
const SECTION_INTERSECTING_CLASS = 'is-intersecting';
const SECTION_BG_VISIBLE_CLASS = 'is-bg-visible';
const ITEM_CLASS = 'js-cases-item';
const ITEM_ACTIVE_CLASS = 'is-active';

const sectionObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(SECTION_BG_VISIBLE_CLASS, SECTION_INTERSECTING_CLASS);
            } else {
                entry.target.classList.remove(SECTION_BG_VISIBLE_CLASS, SECTION_INTERSECTING_CLASS);
            }
        });
    },
    { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
);

const itemObserver = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const section = entry.target.closest(`.${SECTION_CLASS}`);

                if (section) {
                    const casesItems = Array.from(section.querySelectorAll(`.${ITEM_CLASS}`));

                    casesItems.forEach((item) => {
                        item.classList.remove(ITEM_ACTIVE_CLASS);
                    });
                }

                entry.target.classList.add(ITEM_ACTIVE_CLASS);
            } /*  else {
                entry.target.classList.remove(ITEM_ACTIVE_CLASS);
            } */
        });
    },
    { rootMargin: '-50% 0% -50% 0%', threshold: [0, 1] },
);

function onMouseEnter(this: HTMLElement) {
    const section = this.closest(`.${SECTION_CLASS}`);

    if (section) {
        const casesItems = Array.from(section.querySelectorAll(`.${ITEM_CLASS}`));

        casesItems.forEach((item) => {
            item.classList.remove(ITEM_ACTIVE_CLASS);
        });

        section.classList.add(SECTION_BG_VISIBLE_CLASS);
    }

    this.classList.add(ITEM_ACTIVE_CLASS);
}

function onMouseLeave(this: HTMLElement) {
    const section = this.closest(`.${SECTION_CLASS}`);

    if (section && !section.classList.contains(SECTION_INTERSECTING_CLASS)) {
        section.classList.remove(SECTION_BG_VISIBLE_CLASS);
    }
}

function init(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(`.${SECTION_CLASS}`)).forEach((el) => {
        const casesItems = Array.from(el.querySelectorAll(`.${ITEM_CLASS}`));

        casesItems.forEach((item) => {
            item.addEventListener('mouseenter', onMouseEnter);
            item.addEventListener('mouseleave', onMouseLeave);

            if (matchMedia('(pointer: coarse)').matches) {
                itemObserver.observe(item);
            }
        });

        sectionObserver.observe(el);
    });
}

function destroy(container: HTMLElement | Document = document) {
    Array.from(container.querySelectorAll<HTMLElement>(`.${SECTION_CLASS}`)).forEach((el) => {
        const casesItems = Array.from(el.querySelectorAll(`.${ITEM_CLASS}`));

        casesItems.forEach((item) => {
            item.removeEventListener('mouseenter', onMouseEnter);
            item.removeEventListener('mouseleave', onMouseLeave);
            itemObserver.unobserve(item);
        });

        sectionObserver.unobserve(el);
    });
}

export default { init, destroy };
