import createValidator from '../../modules/validator';

const SELECTOR = '.js-validate-form';
const map = new WeakMap<
    HTMLFormElement,
    {
        submitFn: EventListener;
        focusFn: EventListener;
    }
>();

function init(container: HTMLElement | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const stopSubmit = form.classList.contains('js-validate-form--stop-submit');
        let isSubmitting = false;
        const validator = createValidator(form, {
            inputSelector: stopSubmit ? '.js-form-validate-input' : undefined,
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        function submitFn(event: Event) {
            if (isSubmitting) return;
            event.preventDefault();

            const isFormValid = validator.validate();
            form.querySelectorAll('.app-message')?.forEach((el) => el.classList.remove('is-show'));

            if (isFormValid && !stopSubmit) {
                isSubmitting = true;
                form.submit();
            }
        }

        function onFocus(this: HTMLInputElement) {
            form.querySelectorAll('.app-message')?.forEach((el) => el.classList.remove('is-show'));
        }

        validator.inputs.forEach((input) => {
            input.addEventListener('focus', onFocus);
        });
        form.addEventListener('submit', submitFn);
        map.set(form, { submitFn, focusFn: onFocus });
    });
}

function destroy(container: HTMLElement | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(SELECTOR));

    forms.forEach((form) => {
        const data = map.get(form);

        if (data) {
            form.removeEventListener('submit', data.submitFn);
            Array.from(form.querySelectorAll('input')).forEach((input) => {
                input.removeEventListener('focus', data.focusFn);
            });
        }
    });
}

const _module = { init, destroy };

export default _module;
