import lottie, { AnimationItem } from 'lottie-web';
import { timeout } from '../utils/timeout';
import preloaderAnimation from './animations/preloader-loading';
import { ZENO_FRAME_COUNT, sequenceState } from './product-sequence';

let loaded = 0;
let totalAssets = 0;

let sequenceTotalAssets = 0;

const loadAsset = (asset: HTMLImageElement | HTMLVideoElement): Promise<void> => {
    return new Promise((resolve) => {
        const onLoad = () => {
            loaded++;
            resolve();
        };

        if (asset instanceof HTMLImageElement) {
            if (asset.complete) {
                onLoad();
            } else {
                asset.addEventListener('load', onLoad, { once: true });
            }
        } else if (asset instanceof HTMLVideoElement) {
            if (asset.readyState === 4) {
                onLoad();
            } else {
                asset.addEventListener('canplay', onLoad, { once: true });
                asset.addEventListener('error', onLoad, { once: true });
            }
        } else {
            resolve();
        }
    });
};

const init = async () => {
    const { animateTo } = preloaderAnimation.init();

    sequenceTotalAssets = document.querySelector('.js-product-canvas') ? ZENO_FRAME_COUNT : 0;

    const waitForSequenceFramesToLoad = (): Promise<void> =>
        new Promise((resolve) => {
            document.addEventListener('sequence-img-loaded', function onSequenceImgLoaded(event: any) {
                const percent = Math.round((event.detail.loaded / event.detail.total) * 100);

                if (percent >= 100) {
                    document.removeEventListener('sequence-img-loaded', onSequenceImgLoaded);
                    resolve();
                }
            });
        });

    const lottieContainer = document.querySelector<HTMLElement>('.js-preloader-lottie');
    const images = Array.from(document.querySelectorAll<HTMLImageElement>('img:not([loading="lazy"]):not(.lazy)'));
    const videos = matchMedia('(max-width: 1199px)').matches
        ? []
        : Array.from(document.querySelectorAll<HTMLVideoElement>('video:not(.lazy)'));
    const assets = [...images, ...videos];
    totalAssets = assets.length;
    let currentPercent = 0;
    let prevPercent = 0;
    let lottieInstance: AnimationItem | null;

    if (lottieContainer) {
        lottieInstance = lottie.loadAnimation({
            container: lottieContainer, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: '/static/lottie/preloader.json', // the path to the animation json
        });
        lottieInstance.play();
    }

    const load = async () => {
        currentPercent = Math.round(
            ((loaded + (sequenceTotalAssets === 0 ? 0 : sequenceState.loaded)) / (totalAssets + sequenceTotalAssets)) *
                100,
        );

        if (currentPercent > prevPercent) {
            await animateTo(currentPercent);
        } else {
            await timeout(2500);
        }

        prevPercent = currentPercent;

        if (currentPercent < 100) {
            if (sequenceTotalAssets > 0) {
                waitForSequenceFramesToLoad();
            }

            load();
        } else {
            lottieInstance?.destroy();
            document.dispatchEvent(new Event('preloader-leave-complete'));
        }
    };

    if (assets.length > 0) {
        Promise.all(assets.map((asset) => loadAsset(asset)));
        setTimeout(load, 150);
    } else {
        loaded = 1;
        totalAssets = 1;
        load();
    }
};

const _module = { init };

export default _module;
